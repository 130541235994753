<template>
  <TopNav></TopNav>
  <h1>Mil Hesaplama</h1>
  <fieldset>
    <legend>Mil Hesaplama Aracı</legend>
    <input type="number" v-model.number="kilometre" step="any" @input="kilometreDegistiginde" autofocus id="km"> <label for="km"><abbr title="kilometre">㎞</abbr></label>
    <br>
    <input type="number" v-model.number="mil" step="any" @input="milDegistiginde" id="mil"> <label for="mil"><abbr title="mil">mi</abbr></label>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Mil",
  data() {
    return {
      kilometre: 0,
      mil: 0
    }
  },
  components: {
    TopNav
  },
  methods: {
    kilometreDegistiginde(e) {
      const kilometre = e.currentTarget.value;
      this.mil = kilometre * 0.621371;
    },
    milDegistiginde(e) {
      const mil = e.currentTarget.value;
      this.kilometre = mil * 1.609344;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 120px;
}

footer section a {
  margin: 20px;
}
</style>
